import { empty, promisifiedAjax } from '@/utils/functions';

/**
 * Dependencies:
 * data:
 *  - model
 *   - related_customer_id
 * methods:
 *  - setAddressAction
 *  - setCoordinateAction
 *  - renderTemplates
 */
export default {
    data() {
        return {
            showRelatedServices: false,
            serviceRelated: {},
        };
    },
    methods: {
        changeRelatedServices(value) {
            if (!empty(value)) {
                promisifiedAjax({
                    url: '/admin/scheduling/tasks--get-geo-data-by-service',
                    data: {
                        service_id: value,
                        customer_id: this.model.related_customer_id,
                    },
                    method: 'POST',
                }).then((response) => {
                    if (response.result === true) {
                        if (!empty(response.address)) {
                            this.setAddressAction(response.address);
                        }
                        if (!empty(response.marker)) {
                            this.setCoordinateAction(response.marker);
                        }
                    }
                });
            } else {
                this.setAddressAction('');
                this.setCoordinateAction('');
            }

            this.renderTemplates();
        },
        getRelated() {
            let customerId = this.model.related_customer_id || 0;
            promisifiedAjax({
                url: `/admin/scheduling/tasks--load-services?customerId=${customerId}`,
            }).then((response) => {
                if (empty(response)) {
                    this.showRelatedServices = false;
                    this.serviceRelated = { 0: this.$t('scheduling', 'No services') };
                } else {
                    this.serviceRelated = response;
                    this.showRelatedServices = true;
                }
            });
        },
    },
};
