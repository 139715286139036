import { mapActions, mapGetters } from 'vuex';
import { promisifiedAjax } from '@/utils/functions';

// frontend/apps/admin/store/modules/scheduling/scheduling_calendars
const STORE = 'scheduling_calendars';

/**
 * Dependencies:
 * data:
 *  - model
 *   - gps
 *   - id
 *   - address
 * computed:
 *  - disableFields
 */
export default {
    methods: {
        /** @method Call it after loadData */
        __coordinatesMixin_loadData() {
            this.setCoordinate(this.model.gps);
        },
        ...mapActions(STORE, [
            'setCoordinateAction',
        ]),
        setCoordinate(gps) {
            this.setCoordinateAction(gps);
        },
        addGeoData() {
            return new Promise((resolve) => {
                promisifiedAjax({
                    url: `/admin/scheduling/tasks--add-geo-data-task?id=${this.model.id}&geocode=true`,
                    method: 'POST',
                    data: {
                        address: this.model.address,
                    },
                }).then((response) => {
                    if (response.result) {
                        this.setCoordinate(response.coordinates);
                        this.$showSuccess(response.message, 5);
                    } else {
                        this.$showError(response.message, 5);
                    }
                    resolve();
                });
            });
        },
    },
    computed: {
        coordinateParams() {
            return {
                options: {
                    id: 'scheduling_task_preview_coordinate',
                    type: 'text',
                    disabled: true,
                },
                button: [
                    {
                        id: 'scheduling_task_preview_get_coordinate',
                        class: 'icon-ic_fluent_my_location_24_regular ',
                        options: { class: 'btn btn-outline-secondary btn-icon cursor-pointer' },
                        handler: () => {
                            if (!this.disableFields) {
                                this.addGeoData();
                            }
                        },
                    },
                    {
                        id: 'scheduling_task_preview_coordinate_button',
                        class: 'icon-ic_fluent_map_24_regular',
                        options: { class: 'btn btn-outline-secondary btn-icon cursor-pointer' },
                        handler: () => {
                            if (!this.disableFields) {
                                showModal('scheduling-task-preview-geo', {
                                    taskId: this.model.id,
                                });
                            }
                        },
                    },
                ],
            };
        },
        ...mapGetters(STORE, [
            'getTaskCoordinate',
        ]),
    },
    watch: {
        'model.gps': function (newVal) {
            this.setCoordinateAction(newVal);
        },
        getTaskCoordinate(newVal) {
            if (newVal !== this.model.gps) {
                this.model.gps = newVal;
            }
        },
    },
};
