import XTwigPage from '@/components/base/XTwigPage';

export default [
    {
        path: '',
        name: 'admin.networking.monitoring.view',
        component: XTwigPage,
    },
    {
        delimiter: '--',
        path: 'oid',
        name: 'admin.networking.monitoring.view.oid',
        component: XTwigPage,
        meta: {
            title: ['networking', 'SNMP OID'],
        },
    },
    {
        delimiter: '--',
        path: 'log',
        name: 'admin.networking.monitoring.view.log',
        component: XTwigPage,
        meta: {
            title: ['networking', 'Logs'],
        },
    },
    {
        delimiter: '--',
        path: 'backup',
        name: 'admin.networking.monitoring.view.backup',
        component: XTwigPage,
        meta: {
            title: ['networking', 'Backup'],
        },
    },
    {
        delimiter: '--',
        path: 'map',
        name: 'admin.networking.monitoring.view.map',
        component: XTwigPage,
        meta: {
            title: ['networking', 'Map'],
        },
    },
];
