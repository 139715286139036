/**
 * Dependencies:
 * props:
 *  - parentTaskId
 * data:
 *  - newRecord
 *  - redirectToTaskPage
 * methods:
 *  - hideSidebar
 */
export default {
    methods: {
        beforeSend(data) {
            if (this.parentTaskId) {
                data.parent_task_id = this.parentTaskId;
            }
            return data;
        },
        onFormSuccess(response) {
            if (response.result) {
                if (this.newRecord) {
                    this.$showNotify('success', response.message, 6, false, {
                        name: this.$t('scheduling', 'View'),
                        redirect_url: `/admin/scheduling/tasks--view?id=${response.options.event.id}`,
                    });
                } else {
                    this.$showSuccess(response.message, 4);
                }

                this.hideSidebar();
                reloadLastDataTable();

                if (this.redirectToTaskPage) {
                    this.redirectToTaskPage = !this.redirectToTaskPage;
                    switch_page(`/admin/scheduling/tasks--view?id=${response.options.event.id}`);
                }
                // TODO Rework this, when scheduling task page and subtask card (views/admin/scheduling/tasks/view-sub-tasks-panel/index.twig) will be reworked on Vue
                if (this.parentTaskId) {
                    this.$root.REFRESH_ROUTE();
                }
            } else {
                this.$showError(response.message, 4);
                this.redirectToTaskPage = false;
            }
        },
    },
};
