import { promisifiedAjax } from '@/utils/functions';

/**
 * Dependencies:
 * data:
 *  - model
 *   - id
 */
export default {
    data() {
        return {
            checklist: [],
        };
    },
    methods: {
        renderChecklistTemplate(value) {
            if (!empty(value)) {
                return new Promise((resolve, reject) => {
                    promisifiedAjax({
                        url: `/admin/scheduling/tasks--load-checklist-json?task=${this.model.id}&checklist=${value}`,
                        method: 'GET',
                    }).then((response) => {
                        this.checklist = response.check_list;
                        resolve();
                    }).catch((error) => {
                        reject(error);
                    });
                });
            }
            this.checklist = [];
        },
    },
};
