/**
 * Dependencies:
 * props:
 *  - projectId
 *  - newRecord
 * data:
 *  - options
 *   - statuses
 *  - model
 *   - title
 *   - workflow_status_id
 * methods:
 *  - doesHasCurlyBrackets:
 *  - changeRenderForTemplates:
 *  - renderTemplates:
 *  - checkPartners:
 */
export default {
    data() {
        return {
            relatedToProjectData: {},
        };
    },
    methods: {
        /** @method Call it after loadData */
        __changeHandlersMixin_loadData() {
            if (this.projectId && this.newRecord) this.changeProject(this.model.project_id);
        },
        changeTitle() {
            if (this.doesHasCurlyBrackets(this.model.title)) {
                this.changeRenderForTemplates('schedulingtask-title', this.model.title);
                this.renderTemplates();
            }
        },
        changeDescription() {
            if (this.doesHasCurlyBrackets(this.model.description)) {
                this.templatesForRender.admin_scheduling_task_create_description_area_froala_input = this.model.description;
                this.renderTemplates();
            }
        },
        changeAdmin() {
            this.checkPartners();
            this.renderTemplates();
        },
        changeProject(value) {
            if (value !== null && isset(this.relatedToProjectData, [value])) {
                this.checkPartners();
                this.options.statuses = this.relatedToProjectData[value].statuses;
                this.$nextTick(() => {
                    this.model.workflow_status_id = this.relatedToProjectData[value]?.statuses[0]?.id;
                });
            }
        },
    },
};
