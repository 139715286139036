import { empty } from '@/utils/functions';

export default {
    data() {
        return {
            errorMessages: [],
            warningIncorrectValues: {},
        };
    },
    methods: {
        getWarningMessage(attr) {
            this.errorMessages[attr] = [];
            if (!empty(this.warningIncorrectValues) && this.warningIncorrectValues[attr]?.partner) {
                this.errorMessages[attr].push(this.$t('scheduling', 'partner'));
            }
            if (!empty(this.warningIncorrectValues) && this.warningIncorrectValues[attr]?.project) {
                this.errorMessages[attr].push(this.$t('scheduling', 'parent project'));
            }

            return this.$t('scheduling', 'Incorrect: {errors}!', {
                errors: this.errorMessages[attr].join(this.$t('scheduling', ' and ')),
            });
        },
        showWarningIncorrectValues(attr) {
            return !empty(this.warningIncorrectValues) && (this.warningIncorrectValues[attr]?.partner || this.warningIncorrectValues[attr]?.project);
        },
    },
};
