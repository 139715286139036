import { empty, promisifiedAjax } from '@/utils/functions';

/**
 * Dependencies:
 * data:
 *  - className
 *  - options
 *   - customer_options
 *  - model
 *   - partner_id
 *   - location_id
 *   - related_customer_id
 * methods:
 *  - showWarningIncorrectValues
 *  - setAddressAction
 *  - setCoordinateAction
 *  - checkPartners
 *  - getRelated
 *  - renderTemplates
 */
export default {
    data() {
        return {
            needShowServiceField: false,
        };
    },
    methods: {
        /** @method Call it after loadData */
        __customerMixin_loadData(data) {
            this.needShowServiceField = data.resources.options.need_show_service_field;
            this.relatedToProjectData = data.resources.related_to_project_data;
        },
        processDataFromSelect() {
            let selectCustomerData = $('#scheduling_task_preview_related_customer_id').select2('data');
            if (!empty(selectCustomerData[0]?.partner_id)) {
                this.model.partner_id = selectCustomerData[0].partner_id;
            }

            if (!empty(selectCustomerData[0]?.location_id)) {
                this.model.location_id = selectCustomerData[0].location_id;
            }
        },
        changeCustomer(newVal) {
            if (Array.isArray(newVal)) {
                this.model.related_customer_id = newVal[0].id;
            }

            if (!empty(this.model.related_customer_id)) {
                promisifiedAjax({
                    url: '/admin/scheduling/tasks--get-geo-data-by-customer-id',
                    data: {
                        customer_id: this.model.related_customer_id,
                    },
                    method: 'POST',
                }).then((response) => {
                    if (response.result === true) {
                        if (!empty(response.address)) {
                            this.setAddressAction(response.address);
                        }
                        if (!empty(response.marker)) {
                            this.setCoordinateAction(response.marker);
                        }
                    }
                    this.processDataFromSelect();
                    this.checkPartners();
                });
            } else {
                this.model.related_service_id = '';
                this.setAddressAction('');
                this.setCoordinateAction('');
            }

            if (this.needShowServiceField) {
                this.getRelated();
            }
            this.renderTemplates();
        },
    },
    computed: {
        customerAllParams() {
            let self = this;
            return {
                isAsync: true,
                config: {
                    data: this.options.customer_options,
                    ajax: {
                        url: '/admin/scheduling/tasks--find-customers',
                        delay: 250,
                        data(params) {
                            return {
                                login: params.term,
                                partner_id: !empty(self.model.partner_id) ? self.model.partner_id : 0,
                            };
                        },
                        processResults(data) {
                            return {
                                results: data.data,
                            };
                        },
                        cache: true,
                    },
                    allowClear: true,
                    placeholder: this.$t('scheduling', 'Select customer'),
                    minimumInputLength: 2,
                },
                options: {
                    id: 'scheduling_task_preview_related_customer_id',
                    name: `${this.className}[related_customer_id]`,
                    class: this.showWarningIncorrectValues('related_customer_id') && this.model.related_customer_id ? 'incorrect' : '',
                    disabled: this.disableFields,
                },
            };
        },
    },
};
