import {
    ADD_ENTRY_POINT,
    DELETE_ENTRY_POINT,
    SET_ENTRY_POINTS,
    SET_ENTRY_POINTS_INFO,
    SET_INSTALL_MODULE_INFO,
} from '../mutation-types';

const state = {
    module: '',
    moduleTitle: 'Module',
    entry_points: [],
    locations: [],
    partners: [],
    paymentTypes: [],
    paymentAccounts: [],
    placesArray: [],
    attributeLabels: {},
    sizeArray: {},
    pointTypesAdmin: {},
    pointTypesPortal: {},
    rootsCode: {},
    rootsTab: {},
    rootsOther: {},
    rootsPortalCode: {},
    rootsPortalOther: {},
    adminModels: {},
    portalModels: {},
    rootsWithBackground: {},
    defaultBackgroundColors: {},
    placeLocations: {},
    moduleLabels: {},
    moduleTypesArray: {},
    installModuleRoots: {},
};

const getters = {
    module(state) {
        return state.module;
    },
    moduleTitle(state) {
        return state.moduleTitle;
    },
    entry_points(state) {
        return state.entry_points;
    },
    attributeLabels(state) {
        return state.attributeLabels;
    },
    placesArray(state) {
        return state.placesArray;
    },
    sizeArray(state) {
        return state.sizeArray;
    },
    pointTypesAdmin(state) {
        return state.pointTypesAdmin;
    },
    pointTypesPortal(state) {
        return state.pointTypesPortal;
    },
    partners(state) {
        return state.partners;
    },
    locations(state) {
        return state.locations;
    },
    paymentTypes(state) {
        return state.paymentTypes;
    },
    rootsCode(state) {
        return state.rootsCode;
    },
    rootsTab(state) {
        return state.rootsTab;
    },
    rootsOther(state) {
        return state.rootsOther;
    },
    rootsPortalCode(state) {
        return state.rootsPortalCode;
    },
    rootsPortalOther(state) {
        return state.rootsPortalOther;
    },
    adminModels(state) {
        return state.adminModels;
    },
    portalModels(state) {
        return state.portalModels;
    },
    paymentAccounts(state) {
        return state.paymentAccounts;
    },
    rootsWithBackground(state) {
        return state.rootsWithBackground;
    },
    defaultBackgroundColors(state) {
        return state.defaultBackgroundColors;
    },
    placeLocations(state) {
        return state.placeLocations;
    },
    moduleLabels(state) {
        return state.moduleLabels;
    },
    moduleTypesArray(state) {
        return state.moduleTypesArray;
    },
    installModuleRoots(state) {
        return state.installModuleRoots;
    },
};

const actions = {
    loadModuleInfo({ commit }, module) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/config/modules/list--get-module-info?module=${module}`,
                success: (response) => {
                    response.module = module;
                    commit(SET_ENTRY_POINTS_INFO, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
    loadInstallModuleInfo({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/config/modules/install--get-info',
                success: (response) => {
                    commit(SET_INSTALL_MODULE_INFO, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
    loadEntryPoints({ commit, state }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/config/modules/list--get-entry-points-of-module?module=${state.module}`,
                success: (response) => {
                    commit(SET_ENTRY_POINTS, response.points);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
    saveEntryPoints({ state, dispatch }, points) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/config/modules/list--save-entry-points?module=${state.module}`,
                method: 'POST',
                data: {
                    points,
                },
                success: (response) => {
                    if (response.result) {
                        dispatch('loadEntryPoints');
                        show_success(window.xApp.$splang.t('config', 'Entry point saved!'), 4);
                        resolve();
                    } else {
                        showErrors(response.message, 6);
                        reject();
                    }
                },
                error: () => {
                    reject();
                },
            });
        });
    },
};

const mutations = {
    [SET_ENTRY_POINTS](state, points) {
        state.entry_points = points;
    },
    [SET_ENTRY_POINTS_INFO](state, info) {
        state.module = info.module;
        state.moduleTitle = info.moduleTitle;
        state.entry_points = info.points;
        state.locations = info.locations;
        state.partners = info.partners;
        state.paymentTypes = info.paymentTypes;
        state.paymentAccounts = info.paymentAccounts;
        state.attributeLabels = info.attributeLabels;
        state.placesArray = info.placesArray;
        state.sizeArray = info.sizeArray;
        state.pointTypesAdmin = info.pointTypesAdmin;
        state.pointTypesPortal = info.pointTypesPortal;
        state.rootsCode = info.rootsCode;
        state.rootsTab = info.rootsTab;
        state.rootsOther = info.rootsOther;
        state.rootsPortalCode = info.rootsPortalCode;
        state.rootsPortalOther = info.rootsPortalOther;
        state.adminModels = info.adminModels;
        state.portalModels = info.portalModels;
        state.rootsWithBackground = info.rootsWithBackground;
        state.defaultBackgroundColors = info.defaultBackgroundColors;
        state.placeLocations = info.placeLocations;
    },
    [SET_INSTALL_MODULE_INFO](state, info) {
        state.moduleLabels = info.moduleLabels;
        state.moduleTypesArray = info.moduleTypesArray;
        state.installModuleRoots = info.installModuleRoots;
    },
    [DELETE_ENTRY_POINT](state, pointIndex) {
        state.entry_points.splice(pointIndex, 1);
    },
    [ADD_ENTRY_POINT](state, point) {
        if (!point) {
            point = {
                id: null,
                background: '',
                code: '',
                deleted: null,
                enabled: true,
                icon: 'fa-cog',
                location: 'before_content',
                location_geo: '',
                model: '',
                module: state.module,
                name: '',
                partners: '',
                payment_account_id: '0',
                payment_type: '',
                place: 'admin',
                root: '',
                size: 'large',
                title: '',
                type: 'code',
                url: '',
            };
        }

        state.entry_points.push(point);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
