const InventoryReportItemsList = () => import(/* webpackChunkName: "inventory_report_items" */ '@/apps/admin/views/admin/inventory/reports/items/InventoryReportItemsList');
const InventorySoldItemsReportList = () => import(/* webpackChunkName: "inventory_sold_items_report" */ '@/apps/admin/views/admin/inventory/reports/sold-items/InventorySoldItemsReportList');

export default [
    {
        path: 'sold-items',
        name: 'admin.inventory.reports.sold_items',
        component: InventorySoldItemsReportList,
        meta: {
            keepAlive: true,
            componentName: 'InventorySoldItemsReportList',
        },
    },
    {
        path: 'inventory-items',
        name: 'admin.inventory.reports.inventory_items',
        component: InventoryReportItemsList,
        meta: {
            keepAlive: true,
            componentName: 'InventoryReportItemsList',
        },
    },
];
