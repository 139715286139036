var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scheduling-preview sidebar-content-inner h-100 px-0 pt-0"},[_c('x-preloader-block',{attrs:{"loading":_vm.loading}},[_c('x-form',{ref:"form",staticClass:"h-100 scheduling-task-preview form-label-start",attrs:{"id":"admin_scheduling_task_preview","url":_vm.formUrl,"method":"POST","prevent-db":"#admin_scheduling_task_preview_save_button","data":_vm.model,"class-name":_vm.className,"attributes-meta":_vm.attributesMeta,"af-attributes-meta":_vm.additionalAttributesMeta,"before-send":_vm.beforeSend},on:{"success":_vm.onFormSuccess}},[_c('div',{staticClass:"form-content"},[(_vm.newRecord)?_c('div',{staticClass:"row",attrs:{"data-test-selector":"task-template-row"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Task template'))+" ")]),_c('div',{staticClass:"col-md-12"},[_c('x-complex-input',{attrs:{"type":"select","data-test-selector":"task-template-select","params":{
                                allowClear: true,
                                placeholder: _vm.$t('scheduling', 'Select template'),
                                options: {
                                    id: 'admin_config_task_templates_select_template',
                                    options: _vm.options.templates,
                                    disabled: _vm.disableFields,
                                },
                            }},on:{"input":_vm.changeTemplate},model:{value:(_vm.template),callback:function ($$v) {_vm.template=$$v},expression:"template"}})],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Title'))+" ")]),_c('div',{staticClass:"col-md-12"},[_c('x-complex-input',{attrs:{"type":"text","meta":_vm.attributesMeta.title,"params":{
                                options: {
                                    id: 'scheduling_task_preview_title',
                                    disabled: _vm.disableFields,
                                },
                            }},on:{"focusout":_vm.changeTitle},model:{value:(_vm.model.title),callback:function ($$v) {_vm.$set(_vm.model, "title", $$v)},expression:"model.title"}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Description'))+" ")]),_c('div',{staticClass:"col-md-12"},[_c('x-complex-input',{ref:"froala",staticClass:"pt-0",attrs:{"type":"froala","params":{
                                options: {
                                    id: 'scheduling_task_preview_description',
                                },
                                toolbarButtons: _vm.toolbarButtons,
                                toolbarSticky: false,
                                mentions: true,
                                mentionsList: _vm.mentionsList,
                                heightMax: 105,
                                disabled: _vm.disableFields,
                            },"meta":_vm.attributesMeta.description},on:{"focusout":_vm.changeDescription},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Project'))+" ")]),_c('x-complex-input',{attrs:{"type":"select","params":{
                                placeholder: _vm.$t('scheduling', 'Select project'),
                                options: {
                                    id: 'scheduling_task_preview_project_id',
                                    options: _vm.options.projects,
                                    class: _vm.showWarningIncorrectValues('project_id') ? 'incorrect' : '',
                                    disabled: _vm.disableFields,
                                },
                            },"meta":_vm.attributesMeta.project_id},on:{"input":_vm.changeProject},model:{value:(_vm.model.project_id),callback:function ($$v) {_vm.$set(_vm.model, "project_id", $$v)},expression:"model.project_id"}}),(_vm.showWarningIncorrectValues('project_id'))?_c('small',{attrs:{"data-test-selector":"project-warning"},domProps:{"innerHTML":_vm._s(_vm.getWarningMessage('project_id'))}}):_vm._e()],1),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Assigned to'))+" ")]),_c('x-complex-input',{attrs:{"type":"select","params":{
                                options: {
                                    id: 'scheduling_task_preview_assigned_value',
                                    options: _vm.options.assignee_list,
                                    class: _vm.showWarningIncorrectValues('assigned_value') ? 'incorrect' : '',
                                    disabled: _vm.disableFields,
                                },
                            },"meta":_vm.attributesMeta.assigned_value},on:{"input":_vm.changeAdmin},model:{value:(_vm.model.assigned_value),callback:function ($$v) {_vm.$set(_vm.model, "assigned_value", $$v)},expression:"model.assigned_value"}}),(_vm.showWarningIncorrectValues('assigned_value'))?_c('small',{attrs:{"data-test-selector":"admin-warning"},domProps:{"innerHTML":_vm._s(_vm.getWarningMessage('assigned_value'))}}):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Priority'))+" ")]),_c('x-complex-input',{attrs:{"type":"select","params":{
                                options: {
                                    id: 'scheduling_task_preview_priority',
                                    options: _vm.options.priorities,
                                    disabled: _vm.disableFields,
                                },
                                templateResult: true,
                                templateSelection: true,
                            },"meta":_vm.attributesMeta.priority},model:{value:(_vm.model.priority),callback:function ($$v) {_vm.$set(_vm.model, "priority", $$v)},expression:"model.priority"}})],1),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Status'))+" ")]),_c('x-complex-input',{attrs:{"type":"select","params":{
                                options: {
                                    id: 'scheduling_task_preview_workflow_status_id',
                                    options: _vm.options.statuses,
                                    disabled: _vm.disableFields,
                                },
                                templateResult: true,
                                templateSelection: true,
                            },"meta":_vm.attributesMeta.workflow_status_id},model:{value:(_vm.model.workflow_status_id),callback:function ($$v) {_vm.$set(_vm.model, "workflow_status_id", $$v)},expression:"model.workflow_status_id"}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Customer'))+" ")]),_c('div',{staticClass:"col-12"},[_c('x-complex-input',{attrs:{"data-test-selector":"customer-select","type":"select","params":_vm.customerAllParams,"meta":_vm.attributesMeta.related_customer_id},on:{"input":_vm.changeCustomer},model:{value:(_vm.model.related_customer_id),callback:function ($$v) {_vm.$set(_vm.model, "related_customer_id", $$v)},expression:"model.related_customer_id"}}),(_vm.showWarningIncorrectValues('related_customer_id'))?_c('small',{attrs:{"data-test-selector":"customer-warning"},domProps:{"innerHTML":_vm._s(_vm.getWarningMessage('related_customer_id'))}}):_vm._e()],1)]),(_vm.showRelatedServices)?_c('div',{staticClass:"row",attrs:{"data-test-selector":"related-services-row"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Related service'))+" ")]),_c('div',{staticClass:"col-12"},[_c('x-complex-input',{attrs:{"type":"select","meta":_vm.attributesMeta.related_service_id,"params":{
                                options: {
                                    id: 'scheduling_task_related_service_id',
                                    options: _vm.serviceRelated,
                                    disabled: _vm.disableFields,
                                },
                                placeholder: _vm.$t('scheduling', 'Select related service'),
                            }},on:{"input":_vm.changeRelatedServices},model:{value:(_vm.model.related_service_id),callback:function ($$v) {_vm.$set(_vm.model, "related_service_id", $$v)},expression:"model.related_service_id"}})],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Partner'))+" ")]),_c('div',{staticClass:"col-md-12"},[_c('x-complex-input',{attrs:{"type":"select","params":{
                                    placeholder: _vm.$t('scheduling', 'Select partner'),
                                    options: {
                                        id: 'scheduling_task_preview_partner_id',
                                        options: _vm.options.partners,
                                        class: _vm.showWarningIncorrectValues('partner_id')? 'incorrect': '',
                                        disabled: _vm.disableFields,
                                    },
                                },"meta":_vm.attributesMeta.partner_id},on:{"input":_vm.checkPartners},model:{value:(_vm.model.partner_id),callback:function ($$v) {_vm.$set(_vm.model, "partner_id", $$v)},expression:"model.partner_id"}})],1)]),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Location'))+" ")]),_c('x-complex-input',{attrs:{"type":"select","params":{
                                options: {
                                    id: 'scheduling_task_preview_location_id',
                                    options: _vm.options.locations,
                                    placeholder: _vm.$t('scheduling', 'Select location'),
                                    disabled: _vm.disableFields,
                                },
                            },"meta":_vm.attributesMeta.location_id},model:{value:(_vm.model.location_id),callback:function ($$v) {_vm.$set(_vm.model, "location_id", $$v)},expression:"model.location_id"}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Address'))+" ")]),_c('div',{staticClass:"col-md-12"},[_c('x-complex-input',{attrs:{"type":"textarea","params":{
                                options: {
                                    id: 'scheduling_task_preview_address',
                                    rows: 2,
                                    disabled: _vm.disableFields,
                                },
                            },"meta":_vm.attributesMeta.address},model:{value:(_vm.model.address),callback:function ($$v) {_vm.$set(_vm.model, "address", $$v)},expression:"model.address"}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Coordinates'))+" ")]),_c('div',{staticClass:"col-md-12"},[_c('x-complex-input',{attrs:{"type":"text","data-test-selector":"gps-input","params":_vm.coordinateParams,"meta":_vm.attributesMeta.gps},model:{value:(_vm.model.gps),callback:function ($$v) {_vm.$set(_vm.model, "gps", $$v)},expression:"model.gps"}})],1)]),(_vm.newRecord)?_c('div',{staticClass:"row",attrs:{"data-test-selector":"check-list-row"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Checklist template'))+" ")]),_c('div',{staticClass:"col-md-12"},[_c('x-complex-input',{attrs:{"type":"select","data-test-selector":"check-list-select","params":{
                                allowClear: true,
                                placeholder: _vm.$t('scheduling', 'Select checklist'),
                                options:{
                                    options: _vm.options.checklists_templates,
                                    disabled: _vm.disableFields,
                                }
                            },"meta":_vm.attributesMeta.checklist_template_id},on:{"input":_vm.renderChecklistTemplate},model:{value:(_vm.model.checklist_template_id),callback:function ($$v) {_vm.$set(_vm.model, "checklist_template_id", $$v)},expression:"model.checklist_template_id"}})],1)]):_vm._e(),(!_vm.empty(_vm.checklist))?_c('div',{staticClass:"row",attrs:{"data-test-selector":"checklists-row"}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"admin-scheduling--tasks-form-checklist-items"},[_c('ul',{staticClass:"ps-16"},_vm._l((_vm.checklist),function(checkItem){return _c('li',{key:checkItem.item.id},[_vm._v(" "+_vm._s(checkItem.item.name)+" ")])}),0)])])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Start date & time'))+" ")]),_c('x-complex-input',{attrs:{"type":"datetime","params":{
                                options: {
                                    id: 'scheduling_task_preview_start_date',
                                    disabled: _vm.disableFields,
                                },
                                datePickerConfig: {
                                    drops: 'up',
                                },
                                activeClearButton: !_vm.disableFields,
                            },"meta":_vm.attributesMeta.scheduled_from},on:{"input":_vm.changeStartDate},model:{value:(_vm.model.scheduled_from),callback:function ($$v) {_vm.$set(_vm.model, "scheduled_from", $$v)},expression:"model.scheduled_from"}})],1),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'End time & day'))+" ")]),_c('x-complex-input',{attrs:{"params":{
                                options: {
                                    id: 'scheduling_task_preview_end_date',
                                    disabled: _vm.disableFields,
                                },
                                datePickerConfig: {
                                    drops: 'up',
                                },
                                activeClearButton: !_vm.disableFields,
                            },"meta":_vm.attributesMeta.scheduled_to,"type":"datetime"},on:{"input":_vm.changeEndDate},model:{value:(_vm.model.scheduled_to),callback:function ($$v) {_vm.$set(_vm.model, "scheduled_to", $$v)},expression:"model.scheduled_to"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Travel time to task location'))+" ")]),_c('x-complex-input',{attrs:{"type":'text',"params":{
                                options: {
                                    id: 'scheduling_task_preview_travel_time_to',
                                    placeholder: _vm.$t('scheduling', '0h 0m'),
                                    disabled: _vm.disableFields,
                                },
                            },"meta":_vm.attributesMeta.formatted_travel_time_to},model:{value:(_vm.model.formatted_travel_time_to),callback:function ($$v) {_vm.$set(_vm.model, "formatted_travel_time_to", $$v)},expression:"model.formatted_travel_time_to"}})],1),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Travel time from task location'))+" ")]),_c('x-complex-input',{attrs:{"type":'text',"params":{
                                options: {
                                    id: 'scheduling_task_preview_travel_time_from',
                                    placeholder: _vm.$t('scheduling', '0h 0m'),
                                    disabled: _vm.disableFields,
                                },
                            },"meta":_vm.attributesMeta.formatted_travel_time_from},model:{value:(_vm.model.formatted_travel_time_from),callback:function ($$v) {_vm.$set(_vm.model, "formatted_travel_time_from", $$v)},expression:"model.formatted_travel_time_from"}})],1)]),(!_vm.newRecord)?_c('div',{staticClass:"row",attrs:{"data-test-selector":"task-preview-timestamps"}},[_c('div',{staticClass:"col"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Created'))+" ")]),_c('div',{attrs:{"id":"scheduling_task_preview_created_at"},domProps:{"textContent":_vm._s(_vm.formatDateTime(_vm.model.created_at))}})]),_c('div',{staticClass:"col"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Updated'))+" ")]),_c('div',{attrs:{"id":"scheduling_task_preview_updated_at"},domProps:{"textContent":_vm._s(_vm.formatDateTime(_vm.model.updated_at))}})])]):_vm._e()]),_c('div',{staticClass:"form-buttons"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-link",attrs:{"id":"admin_scheduling_task_preview_go_to_button","type":"button"},domProps:{"innerHTML":_vm._s(_vm.goToTaskButtonTitle)},on:{"click":_vm.goToTaskPage}})]),_c('div',{staticClass:"col-6 text-end"},[_c('button',{staticClass:"btn btn-cancel",attrs:{"id":"admin_scheduling_task_preview_cancel_button"},on:{"click":function($event){$event.preventDefault();return _vm.hideSidebar.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Cancel'))+" ")]),(!_vm.disableFields)?_c('button',{staticClass:"btn btn-primary",attrs:{"id":"admin_scheduling_task_preview_save_button"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.form.submit()}}},[_vm._v(" "+_vm._s(_vm.$t('scheduling', 'Save'))+" ")]):_vm._e()])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }