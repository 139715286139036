<template>
    <div
        class="splynx-wrapper"
        :class="{'mobile-menu-open': mobileState }"
    >
        <x-license :license="license" />
        <x-header>
            <x-header-list
                v-if="!mobileWidth"
            />
        </x-header>

        <div class="main">
            <x-menu
                ref="menuHolder"
                :app-part="applicationPart"
                :menu-structure="getMenu"
            >
                <x-header-list
                    v-if="mobileWidth"
                />
            </x-menu>

            <div
                id="page_wrapper"
                class="content-wrap"
            >
                <div id="content">
                    <slot />
                </div>
            </div>

            <!-- TODO: delete it -->
            <div
                id="custom_components"
                style="display: none;"
            />
        </div>

        <x-global-notifications />

        <transition name="fade">
            <x-sidebar />
        </transition>

        <x-footer :footer="footer" />

        <x-modal-wrapper ref="modalWrapper" />

        <x-onboarding-stepper />

        <!-- vue-js-modal tooltip component -->
        <tooltip
            :width="230"
            class="vue-js-modal-tooltip"
        />

        <x-tooltip
            :name="'helpTooltip'"
            :width="230"
            class="vue-js-modal-tooltip x-tooltip-content"
        />
    </div>
</template>

<script>
import XMenu from '@/components/base/XMenu';
import XHeader from '@/components/base/XHeader';
import XHeaderList from '@/components/base/XHeader/XHeaderList';
import XFooter from '@/components/base/XFooter';
import XLicense from '@/components/base/XLicense';
import { mapActions, mapGetters } from 'vuex';
import XGlobalNotifications from '@/components/base/XGlobalNotification';
import { XModalWrapper } from '@/plugins/XModalWrapper';
import { XSidebar } from '@/components/base/XSidebar';
import { XTooltip } from '@/components/base/XTooltip';
import { XOnboardingStepper } from '@/components/onboarding/XOnboardingStepper';
import { ADMIN_PART } from '@/constants';

export default {
    name: 'BaseLayout',
    data() {
        return {
            applicationPart: ADMIN_PART,
        };
    },
    components: {
        XGlobalNotifications,
        XMenu,
        XHeader,
        XHeaderList,
        XFooter,
        XLicense,
        XModalWrapper,
        XSidebar,
        XTooltip,
        XOnboardingStepper,
    },
    mounted() {
        this.$nextTick(() => {
            this.addRefs(this.$refs);
        });
    },
    methods: {
        ...mapActions('on_boarding', [
            'addRefs',
        ]),
    },
    computed: {
        ...mapGetters(['getMenu', 'license']),
        ...mapGetters('menu_store', [
            'mobileState',
            'mobileWidth',
        ]),
        ...mapGetters(['footer']),
    },
};
</script>

<style scoped>
.splynx-wrapper {
    transition: opacity 0.5s;
}
</style>
