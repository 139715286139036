const TemplatesList = () => import('@/apps/admin/views/admin/config/templates/TemplatesList');
const TemplatesAdd = () => import('@/apps/admin/views/admin/config/templates/TemplatesAdd');
const TemplatesEdit = () => import('@/apps/admin/views/admin/config/templates/TemplatesEdit');

export default [
    {
        path: '',
        name: 'admin.config.templates',
        component: TemplatesList,
        meta: {
            title: ['config', 'Templates'],
            keepAlive: true,
            componentName: 'TemplatesList',
        },
    },
    {
        delimiter: '--',
        path: 'add',
        name: 'admin.config.templates.add',
        component: TemplatesAdd,
        meta: {
            breadcrumbs: [
                { title: ['config', 'Create template'], url: '/admin/config/templates--add' },
            ],
            title: ['config', 'Create template'],
        },
    },
    {
        delimiter: '--',
        path: 'edit',
        name: 'admin.config.templates.edit',
        component: TemplatesEdit,
        meta: {
            title: ['config', 'Edit template'],
        },
    },
];
