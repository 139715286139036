import { empty, formatDateTime } from '@/utils/functions';

/**
 * Dependencies:
 * data:
 *  - model
 *   - scheduled_from
 *   - scheduled_to
 *   - formatted_duration
 * methods:
 */
export default {
    data() {
        return {
            duration: 1,
            timeUnit: 'minutes',
            format: 'YYYY-MM-DD HH:mm:ss',
        };
    },
    methods: {
        formatDateTime,
        changeStartDate(value) {
            if (empty(value)) {
                return;
            }

            const startDate = moment(value).seconds(0);
            this.model.scheduled_from = startDate.format(this.format);

            if (startDate.isValid()) {
                if (this.duration > 1) {
                    this.model.scheduled_to = startDate.add(this.duration, this.timeUnit).format(this.format);
                    $('#scheduling_task_preview_end_date').data('daterangepicker').minDate = startDate.subtract(this.duration, this.timeUnit).add(5, 'minutes');
                } else {
                    this.model.scheduled_to = startDate.add(60, 'minutes').format(this.format);
                    this.duration = 60;
                    this.model.formatted_duration = '60m';
                    $('#scheduling_task_preview_end_date').data('daterangepicker').minDate = startDate.subtract(55, 'minutes');
                }
            }
            this.$nextTick(() => {
                $('#scheduling_task_preview_end_date').trigger('change');
            });
        },
        changeEndDate(value) {
            if (empty(value)) {
                return;
            }
            const endDate = moment(value).seconds(0);
            this.model.scheduled_to = endDate.format('YYYY-MM-DD HH:mm:ss');

            if (endDate.isValid() && this.model.scheduled_from) {
                let duration = endDate.diff(moment(this.model.scheduled_from), this.timeUnit);
                this.duration = duration > 0 ? duration : 1;
                this.model.formatted_duration = `${this.duration}m`;
            }
        },
    },
};
