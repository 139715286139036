window.is_need_to_reload = false;
window.logout_timer_now = window.time();
window.log_socket = null;

$.fn.dataTable.ext.errMode = function (settings, helpPage, message) {
    show_warning(`Cannot load data, please refresh page... <br>If it repeated several times, please contact the Administrator<br>${message}`, 8);
};

window.webUiPopoverDefaultTemplate = '<div class="webui-popover">'
    + '<div class="webui-arrow"></div>'
    + '<div class="webui-popover-inner">'
    + '<h6 class="webui-popover-title"></h6>'
    + '<div class="webui-popover-content"><i class="icon-refresh"></i> <p>&nbsp;</p></div>'
    + '</div>'
    + '</div>';

window.webUiPopoverUnderModalTemplate = '<div class="webui-popover under-modal">'
    + '<div class="webui-arrow"></div>'
    + '<div class="webui-popover-inner">'
    + '<h6 class="webui-popover-title"></h6>'
    + '<div class="webui-popover-content"><i class="icon-refresh"></i> <p>&nbsp;</p></div>'
    + '</div>'
    + '</div>';

window.logout_timer = function () {
    if (!window.logout_is_enabled) {
        return;
    }

    let current_time = window.logout_time - (window.time() - window.logout_timer_now);
    if (current_time == 0 || current_time == -10 || current_time == -100 || current_time < -1000) {
        logout('/admin/logout');
    }
    $('#auto_logout').html(current_time);
};

window.logout_timer_reset = function () {
    window.logout_timer_now = window.time();
};

if ($.ui && $.ui.dialog && $.ui.dialog.prototype._allowInteraction) {
    let ui_dialog_interaction = $.ui.dialog.prototype._allowInteraction;
    $.ui.dialog.prototype._allowInteraction = function (e) {
        if ($(e.target).closest('.select2-dropdown').length) return true;
        return ui_dialog_interaction.apply(this, arguments);
    };
}

if (window.logout_is_enabled) {
    setInterval(window.logout_timer, 1000);
    $(document).ajaxStart(window.logout_timer_reset);
}

WebuiPopovers.setDefaultOptions({
    template: window.webUiPopoverDefaultTemplate,
});

function onceNewPlacement(target, newPlacement) {
    const old = target.data('plugin_webuiPopover').options.placement;
    target.webuiPopover('hide');
    target.data('plugin_webuiPopover').options.placement = newPlacement;
    target.webuiPopover('show');
    target.data('plugin_webuiPopover').options.placement = old;
}

function toLimitByScreenBounds(target) {
    const popover = target.data('plugin_webuiPopover').$target;
    if (!window.isElementInViewport(popover)) {
        if (popover.offset().left + popover.width() > $(window).width()) {
            onceNewPlacement(target, 'left');
        }
        if (popover.offset().left < 0) {
            onceNewPlacement(target, 'right');
        }
        if (popover.offset().top > $(window).height()) {
            onceNewPlacement(target, 'top');
        }
    }
}

// Search inputs icons
function updateSearchIcon(input) {
    let icon = document.querySelector('.search-icon')?.querySelector('i');
    let className = input.value == ''
        ? 'icon-ic_fluent_search_24_regular'
        : 'icon-ic_fluent_dismiss_24_regular';

    if (icon) {
        icon.removeAttribute('class');
        icon.className = className;
    }

    if (input.value != '') { // crutch for DT
        $(document).on('click', 'div.dataTables_filter .search-icon', (event) => {
            event.preventDefault();
            $('div.dataTables_filter input').val('').trigger('input').focus();
            updateSearchIcon(input);
        });
    }
}

let setSearchIcon = () => {
    let inputs = document.querySelectorAll('.dataTables_filter');
    if (!inputs.length) {
        return;
    }
    let input = inputs[0].querySelector('input');
    if (input === undefined) return;
    let label = input.parentElement;
    let searchButton = document.createElement('button');
    let searchIcon = document.createElement('i');
    searchButton.classList = 'search-icon';
    searchIcon.classList = 'icon-ic_fluent_search_24_regular';
    searchButton.appendChild(searchIcon);
    label.appendChild(searchButton);

    $(document).on('input', 'div.dataTables_filter input', () => {
        updateSearchIcon(input);
    });
    updateSearchIcon(input);
};

$.extend($.fn.DataTable.defaults, {
    autoWidth: false,
    responsive: true,
    deferRender: true,
    stateSave: true,
    processing: true,
    sPaginationType: 'full_numbers',
    lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, 'All'],
    ],
    oLanguage: {
        oPaginate: {
            sFirst: "<i class='icon-ic_fluent_arrow_previous_24_regular'></i>",
            sPrevious: "<i class='icon-ic_fluent_chevron_left_24_regular'></i>",
            sNext: "<i class='icon-ic_fluent_chevron_right_24_regular'></i>",
            sLast: "<i class='icon-ic_fluent_arrow_next_24_regular'></i>",
        },
        sEmptyTable: 'No data available in table',
        sInfo: 'Showing _START_ to _END_ of _TOTAL_ entries',
        sInfoEmpty: 'Showing 0 to 0 of 0 entries',
        sInfoFiltered: '(filtered from _MAX_ total entries)',
        sInfoPostFix: '',
        sLengthMenu: 'Show _MENU_ entries',
        sLoadingRecords: 'Loading...',
        sProcessing: 'Processing...',
        search: '_INPUT_',
        sSearch: '',
        sSearchPlaceholder: 'Table search',
        sUrl: '',
        sZeroRecords: 'No matching records found',
        oAria: {
            sSortAscending: ': activate to sort column ascending',
            sSortDescending: ': activate to sort column descending',
        },
    },
    iDisplayLength: 100,
    dom: "<'row'<'col-md-12'<'table_title table-title'>>><'row'<'col-md-12'<'dt-actions'<'dt-show-items'l>"
        + "<'dt-search'f><'dt-buttons-wrap'B>r>>><'row dt-under-header'>t<'row justify-content-end under-table'<'info' i>p>",
    fnInitComplete(oSettings) {
        const select = '.dataTables_length select';
        $(select).select2({
            minimumResultsForSearch: -1,
        });

        let selectObject = $(select).data('select2');
        if (selectObject !== undefined && '$dropdown' in selectObject) {
            selectObject.$dropdown.find('.select2-results__options').addClass('select2-no-scrollbar');
        }

        // Update lengthMenu independ of sever side enable or not
        window.drawMenuLengthForDataTable(oSettings.sTableId, oSettings.oFeatures.bServerSide, oSettings._iDisplayLength);
    },
    drawCallback() {
        this.api().columns.adjust().responsive.recalc();
    },
});

$(document).on('init.dt', () => {
    let icon = $('div.dataTables_filter .search-icon').length > 0;
    if (icon) return;
    setSearchIcon();
});

$('body').on('change', 'select.tariff-partner-select', (e) => {
    $.ajax({
        url: '/admin/tariffs--get-tariffs-by-partners',
        data: {
            partners: $(e.target).val(),
            type: $(e.target).data('tariff-type'),
            excludeId: $(e.target).data('exclude-id'),
            checkIsActive: $(e.target).data('check-is-active'),
        },
    }).done((data) => {
        let targetElement = $($(e.target).attr('data-tariff-target'));
        let targetElementValues = targetElement.val();
        let options = '';
        $.each(data, (key, value) => {
            options += `<option value=${key}>${value}</option>`;
        });

        targetElement.html(options);

        if (targetElementValues) {
            $.each(targetElementValues, (i, e) => {
                targetElement.find(`option[value='${e}']`).prop('selected', true);
            });
        }

        targetElement.multipleSelect('refresh');
    }).fail(() => {
        showErrors();
    });
});

$(document).on('preXhr.dt', () => {
    $('.dataTables_filter input').attr('readonly', 'readonly');
});

$(document).on('draw.dt', () => {
    $('.dataTables_filter input').removeAttr('readonly');
});

window.getColoredStateLocalStorage = function (table) {
    let id = table.getAttribute('id');

    let localStorageValue = localStorage.getItem(`coloredState${id}DataTable`);
    if (empty(localStorageValue)) {
        return;
    }
    window.localStateColoredButton = localStorageValue === 'true';
    return window.localStateColoredButton;
};

window.toggleColoredClasses = function (table, localStateColoredButton) {
    let button = document.getElementById('toggle_color_button');
    let tableWrapper = button.closest('.dataTables_wrapper');
    let id = table.getAttribute('id');
    button.classList.toggle('active');
    tableWrapper.classList.toggle('table-not-colored');
    table.classList.toggle('table-colored');
    localStateColoredButton = !localStateColoredButton;
    window.saveColoredStateLocalStorage(id, localStateColoredButton);
};

window.saveColoredStateLocalStorage = function (id, localStateColoredButton) {
    if (!supports_html5_storage()) {
        return;
    }
    localStorage.setItem(`coloredState${id}DataTable`, localStateColoredButton);
};

window.coloredRow = function (tableId) {
    let button = document.createElement('button');
    let icon = document.createElement('i');
    let buttonWrap = document.querySelector('.dt-buttons-wrap');
    let tableWrapper = document.querySelector('.dataTables_wrapper');
    let table = tableId ? document.getElementById(tableId) : tableWrapper.querySelector('table');
    let buttonGroup = document.querySelector('.dt-buttons');
    let buttonClassList = ['btn', 'btn-outline-secondary', 'btn-icon', 'me-8', 'active'];
    let localStateColoredButton = window.getColoredStateLocalStorage(table);
    if (!localStateColoredButton) {
        buttonClassList.pop();
        tableWrapper.classList.add('table-not-colored');
        table.classList.remove('table-colored');
    } else {
        table.classList.add('table-colored');
    }

    button.setAttribute('type', 'button');
    button.setAttribute('id', 'toggle_color_button');
    button.setAttribute('title', 'Toggle colored rows according to status');
    button.classList.add(...buttonClassList);
    icon.classList.add('icon-ic_fluent_color_24_regular');
    button.appendChild(icon);
    buttonWrap.insertBefore(button, buttonGroup);

    button.addEventListener('click', (event) => {
        event.stopPropagation();
        this.toggleColoredClasses(table, localStateColoredButton);
    }, true);
};

/* Address manager */

window.content_id = function (id) {
    if (id.indexOf('#') !== -1) {
        id = id.substring(0, id.indexOf('#'));
    }

    let urls = [
        'view',
        'edit',
        'admin/config/additional-fields',
        'admin/tariffs/fup',
        'admin/crm/leads/add',
        'admin/tickets/create',
        'admin/tickets/opened',
        'admin/scheduling/tasks',
        'admin/tariffs/internet--change-tariff-on-services',
        'admin/tariffs/voice--change-tariff-on-services',
        'admin/tariffs/recurring--change-tariff-on-services',
        'admin/crm/leads/convertation',
        'admin/crm/leads/add',
        'admin/crm/leads/list',
        'admin/customers/add',
        'admin/finance/invoices',
        'admin/inventory/items',
        'admin/inventory/products',
        'admin/inventory/supply/suppliers',
        'admin/inventory/supply/supplier-invoices',
        'admin/networking/acs/devices',
    ];

    $.each(urls, (i, value) => {
        if (id.indexOf(value) != -1 && id.indexOf('?') != -1) {
            id = id.substring(0, id.indexOf('?'));
            return false;
        }
    });

    // Trim last slash
    if (id[id.length - 1] === '/') {
        id = id.slice(0, -1);
    }

    id = id.replace(/[^a-z0-9 ,.!]/ig, '_');
    return id;
};

window.createHTMLDiv = function (id2) {
    let div_id = window.content_id(id2);

    if ($('#content').find(`#content${div_id}`).length == 0) {
        $('<div/>', {
            id: `content${div_id}`,
            class: 'content clearfix',
        }).appendTo('#content');
    }
};

window.processHTML = function () {
    // if this == ajax object then this.url exist. Example: default ajax call $.ajax({...})
    // if this == dom object then this.url is not exist Example: $(selector).load('some.url', null, processHTML)
    // @see https://jira.splynx.com/browse/SPL-4070

    // if (this.url !== undefined) {
    //     [requestUrl] = this.url.split(/[?#]/);
    //     let curentUrl = $.address.value();
    //
    //     if (requestUrl.indexOf(curentUrl.split(/[?#]/)[0]) !== 0) {
    //         return;
    //     }
    //
    //     // SPL-4592 Fix, remove scheduling tasks content section after reload
    //
    //     admin_scheduling_tasks_list_last_data_table = null;
    //     $('#content_admin_scheduling_tasks').remove();
    //
    //     $(".content").hide().removeClass('active');
    //     createHTMLDiv(curentUrl);
    // }
    //
    // var content = xhr.responseText;
    // if (xhr.status === 500) {
    //     if (typeof xhr.responseJSON.html != 'undefined') {
    //         content = xhr.responseJSON.html;
    //     }
    // }
    // $(document).trigger('switch_page', true);
    // $('#content' + content_id($.address.value())).show().html(content).addClass('active');
    // var current_title = />([^<]*)<\/title/.exec(xhr.responseText);
    // if (current_title) current_title = current_title[1];
    // if (current_title) {
    //     $.address.title(window.admin_config_admin_title + ': ' + current_title.replace(/&amp;/g, '&'));
    //     $('.page-name').empty().text(current_title.replace(/&amp;/g, '&'));
    // } else {
    //     $.address.title(window.admin_config_admin_title);
    //     $('.page-name').empty().text(window.admin_config_admin_title);
    // }
    //
    // makeSelect2();
    //
    // makeMultileSelect();
    //
    // makeDatepicker();
    //
    // makeDecimalInput();
    //
    // processPasswords();
    //
    // processDT();
    //
    // processCollapsedPanels();
    //
    // processValidationgInputs();
    //
    // initReportValidityBeforeSubmitForm();
    //
    // addBeforeSubmitValidation();
    //
    // $(document).trigger('after_switch_page', true);
};

window.switch_page = async function (url, event, force = false) {
    if (url[0] !== '/') {
        url = `/${url}`;
    }
    return new Promise((resolve, reject) => {
        window.closeAllDialogs();
        let handleSwitchPage = () => {
            splynx_event_bus.off('after_switch_page', handleSwitchPage);
            resolve(true);
        };
        splynx_event_bus.on('after_switch_page', handleSwitchPage);

        if (window.checkIfNeedOpenLinkInNewTab(event)) {
            window.open(url, '_blank');
            resolve(true);
            return;
        }

        if (force) {
            xApp.$router.push({ force: true, path: url });
        } else {
            xApp.$router.push(url, () => {
            }, () => {
                reject();
            });
        }
    }).catch(() => {
    });
};

window.checkIfNeedOpenLinkInNewTab = function (event) {
    return event && (event.metaKey || event.ctrlKey || event.button === 1);
};

window.fixNumber = function (refObject) {
    let { value } = refObject;
    if (value.length == 0) return true;

    let value2 = refObject.value.replace(',', '.');

    if (value != value2) {
        refObject.value = value2;
    }

    let parsedValue;
    let fs = refObject.value.charAt(0);

    if (fs == '-' && refObject.value.length == 1) {
        return true;
    }

    try {
        parsedValue = parseFloat(refObject.value, 10);

        // Non-number input check.
        if (parsedValue != refObject.value) {
            if ((`${parsedValue}`) == 'NaN') {
                refObject.value = 0;
                parsedValue = 0;
                window.fixNumber(refObject);
            }

            refObject.value = parsedValue;

            if (parsedValue == 0) {
                refObject.select();
            }
        }
    } catch (eException) {
        console.warn(`Fix number error: ${eException}`);
    }
};

window.showCoursorLoadWhenAjaxCall = true;

$(document).ajaxStart(() => {
    if (window.showCoursorLoadWhenAjaxCall) {
        $('body').addClass('wait');
        NProgress.configure({ showSpinner: false });
        NProgress.start();
    }
}).ajaxComplete(() => {
    $('body').removeClass('wait');
    NProgress.done();
});

/**
 * Check if IP/Host value is similar to ip address, if yes then validate it as ip address
 * @param value
 *  @returns {boolean}
 */
window.ifItIpValidateIt = function (value) {
    // minimum ip length
    if (value.length < 7) {
        return true;
    }
    // check or value is similar to the ip address
    if (/^\d+\.\d+\.\d+\.\d+$/.test(value) == false) {
        return true;
    }
    let regexp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    return regexp.test(value);
};

window.showAjaxErrors = true;

window.makePopover = function () {
    Array.from(document.querySelectorAll('[data-bs-toggle="popover"]'))
        .forEach((popover) => new Popover(popover, {
            trigger: 'hover focus',
        }));
};

// Changed to 'dispose' in code but let it be here for reliability
// $pop.popover('destroy'); // jQuery < 4.1
// $pop.popover('dispose'); // jQuery > 4.1
$.prototype.popover = (function (popover) {
    return function (config) {
        try {
            return popover.call(this, config);
        } catch (ex) {
            if (ex instanceof TypeError && config === 'destroy') {
                return popover.call(this, 'dispose');
            }
        }
    };
}($.prototype.popover));

$(document).on('click', '.active-tooltip', function (e) {
    let activeTooltip = $(this).data('active-tooltip');
    if (!activeTooltip) {
        return;
    }
    e.stopImmediatePropagation();
    $('.active-tooltip').not(this).each(function () {
        if ($(this).hasClass('active-tooltip-show')) {
            $(this).webuiPopover('destroy');
            $(this).removeClass('active-tooltip-show');
        }
    });

    if ($(this).hasClass('active-tooltip-show')) {
        $(this).webuiPopover('destroy');
        $(this).removeClass('active-tooltip-show');
        return;
    }
    let self = this;
    let params;
    if (window.isValidJSON($(this).attr('data-active-tooltip-params'))) {
        params = JSON.parse($(this).attr('data-active-tooltip-params'));
    } else {
        params = $(this).attr('data-active-tooltip-params');
    }
    $(document).trigger(activeTooltip, [params, function (data) {
        let hidePopoverHandl;
        let defaultOpt = {
            type: 'html',
            placement: 'auto-bottom',
            trigger: 'manual',
        };
        let options = Object.assign(defaultOpt, data);
        $(self).webuiPopover(options).webuiPopover('show');
        $(self).addClass('active-tooltip-show');

        toLimitByScreenBounds($(self));

        hidePopoverHandl = function (e) {
            if ($(e.target).parents('.webui-popover').length) {
                return;
            }
            $(document).off('click', hidePopoverHandl);
            $(self).webuiPopover('destroy');
            $(self).removeClass('active-tooltip-show');
        };

        $(document).on('click', hidePopoverHandl);
    }, function () {
        $(self).webuiPopover('destroy');
        $(self).removeClass('active-tooltip-show');
    }]);
    params = null;
});

/** Event for reload DT */
window.reload_data_table_event = undefined;

/**
 * Reload DataTable by event
 */
window.reloadLastDataTable = function () {
    if (typeof window.reload_data_table_event === 'function') {
        window.reload_data_table_event();
    }
};

/**
 * Create event for reload DataTable
 * @param table DataTable|string
 * @param savePage boolean - Refresh page in reload
 */
window.createReloadDataTableEvent = function (table, savePage = false) {
    if (typeof table === 'string') {
        let $table = $(table);
        if (!$table.length) {
            return;
        }

        table = $table.DataTable();
    }

    window.reload_data_table_event = function () {
        table.column(0).checkboxes.deselectAll();
        table.ajax.reload(null, savePage);
        window.reload_data_table_event = undefined;
    };
};

/**
 * Function run standart process after submit form in mas cations
 * @param message string
 * @param table DataTable
 */
window.massActionSuccess = function (message, table) {
    closeLastDialog();
    show_success(message, 4);
    table.column(0).checkboxes.deselectAll();
    reloadLastDataTable();
};

window.loadVueComponents = function (componentNames, environment, cb) {
    window.loadVueComponentsByContext('admin', componentNames, environment, cb);
};

window.addEventListener('beforeunload', (e) => {
    if (isHaveUnsavedData($('#content, .splynx-dialog-content'))) {
        let message = t('common', 'Are you sure that you want to leave this page?');
        (e || window.event).returnValue = message;

        return message;
    }
});

/**
 * Redone method that get files, without webkitEntries
 * Fix for dublicates files on savari v11+
 * https://jira.splynx.com/browse/SPL-5087
 * It is safari bug. If fixed in the future, this code can be deleted
 */
$.widget('blueimp.fileupload', $.blueimp.fileupload, {
    _getSingleFileInputFiles(fileInput) {
        // eslint-disable-next-line no-param-reassign
        fileInput = $(fileInput);
        let files;
        let value;

        files = $.makeArray(fileInput.prop('files'));
        if (!files.length) {
            value = fileInput.prop('value');
            if (!value) {
                return $.Deferred()
                    .resolve([])
                    .promise();
            }
            // If the files property is not available, the browser does not
            // support the File API and we add a pseudo File object with
            // the input value as name with path information removed:
            files = [{ name: value.replace(/^.*\\/, '') }];
        } else if (files[0].name === undefined && files[0].fileName) {
            // File normalization for Safari 4 and Firefox 3:
            $.each(files, (index, file) => {
                file.name = file.fileName;
                file.size = file.fileSize;
            });
        }
        return $.Deferred()
            .resolve(files)
            .promise();
    },
});

window.registerOnPageAdmin = function (page, adminInfo, switchPageHandler) {
    if (typeof adminInfo == 'undefined' || !adminInfo) {
        console.error('adminInfo is null!!!');
    }
    let socket = null;
    let disconnectTimer = null;
    let savePosition;
    let getSavedPosition;
    let onBlur;
    let onFocus;
    let refreshOutsidePositions;

    let makeWidget = function (list) {
        let wrapper = $('<div id="spl_admins_on_page_wrapper">');

        list.forEach((admin) => {
            let avatarEl = '';
            if (empty(admin.avatar.url)) {
                avatarEl = `<span class="default_avatar_letter" title="${admin.name}">${admin.avatar.letter}</span>`;
            } else {
                avatarEl = `<img src="${admin.avatar.url}" alt="${admin.name}" title="${admin.name}" onload="resizeImage(this)">`;
            }
            wrapper.append(
                `<div class="avatar">
                    <span class="avatar-wrap default-avatar default_color--'}${admin.avatar.color}">
                        ${avatarEl}
                    </span>
                </div>`,
            );
        });

        $('#spl_admins_on_page_wrapper').remove();

        let savedPosition = getSavedPosition();
        if (!empty(savedPosition)) {
            $(wrapper).css('bottom', 'auto');
            $(wrapper).css('right', 'auto');
            $(wrapper).css('top', `${savedPosition.y}px`);
            $(wrapper).css('left', `${savedPosition.x}px`);
        }

        $('.main').append(wrapper);
        $('#spl_admins_on_page_wrapper').draggable({
            containment: 'window',
            grid: [10, 10],
            stop() {
                $('.main').css('overflow', '');
                savePosition();
            },
            start() {
                $('.main').css('overflow', 'hidden');
                $(wrapper).css('bottom', 'auto');
                $(wrapper).css('right', 'auto');
            },
        });
        refreshOutsidePositions();
        $(window).resize(refreshOutsidePositions);
    };

    let getStorageKey = function () {
        return `spl_on_page_admins_position_${page.replace(/[^\w]/gi, '_')}`;
    };

    savePosition = function () {
        if (!supports_html5_storage()) {
            return;
        }

        let key = getStorageKey();
        let wrapperPos = $('#spl_admins_on_page_wrapper').position();
        let value = `${wrapperPos.left}|${wrapperPos.top}`;
        localStorage.setItem(key, value);
    };

    getSavedPosition = function () {
        if (!supports_html5_storage()) {
            return null;
        }

        let key = getStorageKey();
        let value = localStorage.getItem(key);
        if (value === null) {
            return null;
        }

        let parsedValue = value.split('|');
        return { x: parsedValue[0], y: parsedValue[1] };
    };

    let disableOnPageAdmins = function () {
        $('#spl_admins_on_page_wrapper').remove();
        // $.address.off('change', changeAddressHandler);
        if (socket !== null) {
            socket.disconnect();
            socket = null;
        }

        $(window).off('blur', onBlur);
        $(window).off('focus', onFocus);
    };

    let disconnectByTimeout = function () {
        if (socket) {
            socket.disconnect();
            socket = null;
        }
    };

    let initSocketConnection = function () {
        socket = io.connect(`//${window.location.host}/on_page_admins`);
        socket.on('connect', () => {
            socket.emit('open_page', {
                page,
                admin: adminInfo,
            });

            // remove listeners before creating of new
            socket.removeAllListeners('list_on_page_admins');
            socket.on('list_on_page_admins', (data) => {
                let adminsExcludeCurrent = data.filter((admin) => admin.id !== adminInfo.id && admin.login !== 'splynx-remote-support');

                if (empty(adminsExcludeCurrent)) {
                    $('#spl_admins_on_page_wrapper').remove();
                } else {
                    makeWidget(adminsExcludeCurrent);
                }
            });
        });
    };

    onBlur = function () {
        // User hide the site
        if (disconnectTimer) {
            clearTimeout(disconnectTimer);
        }
        disconnectTimer = setTimeout(disconnectByTimeout, 2 * 60 * 1000); // 2 minutes
    };

    onFocus = function () {
        // User back to the site
        if (disconnectTimer) {
            clearTimeout(disconnectTimer);
            disconnectTimer = null;
        }

        if (socket === null) {
            initSocketConnection();
        }
    };

    refreshOutsidePositions = function () {
        let dragElement = $('#spl_admins_on_page_wrapper');
        if (dragElement.length === 0) return false;
        let windowWidth = $(window).width();
        let windowHeight = $(window).height();
        let isRightOutside = windowWidth - dragElement.outerWidth() < dragElement.position().left;
        let isTopOutside = windowHeight - dragElement.outerHeight() < dragElement.position().top;
        if (isRightOutside) {
            dragElement.css({ left: '', right: '' });
        }
        if (isTopOutside) {
            dragElement.css({ top: '', bottom: '' });
        }
    };

    initSocketConnection();

    let addressOfRegistration = $.address.value();
    let changeAddressHandler = function (e) {
        if (e.value.indexOf(addressOfRegistration) === -1) {
            disableOnPageAdmins();
        }
    };
    $.address.change(changeAddressHandler);
    if (typeof switchPageHandler === 'function') {
        switchPageHandler(disableOnPageAdmins);
    }

    $(window).blur(onBlur);
    $(window).focus(onFocus);
};

$(document).on('click', '.link-with-href', function () {
    switch_page($(this).attr('href'));
    return false;
});

$(document).on('click', '.link-with-href-no-cache', function () {
    window.switch_page_without_cache($(this).attr('href'));
    return false;
});

// Add global translate method alias for all Vue instances
Vue.mixin({
    methods: {
        t(category, message, params) {
            return t(category, message, params);
        },
    },
});

// Register function for show/hide the processing indicator via the API
// @see https://datatables.net/plug-ins/api/processing()
jQuery.fn.dataTable.Api.register('processing()', function (show) {
    return this.iterator('table', (ctx) => {
        ctx.oApi._fnProcessingDisplay(ctx, show);
    });
});

// check and reset to default inputs

window.checkDefaultCond = function (val, defaultVal, partnerVal, element) {
    let isNotDefaultPartner = function (element) {
        if (typeof element === 'undefined') {
            return false;
        }
        const elementPartnerId = element.data('partner-id');
        return (typeof elementPartnerId !== 'undefined' && elementPartnerId !== 0);
    };
    const cond = {
        showDefault: false,
        showPartner: false,
    };
    if (defaultVal !== '' && val != defaultVal) {
        cond.showDefault = true;
    }
    if (
        partnerVal != null && val != partnerVal
        && isNotDefaultPartner(element)
    ) {
        cond.showPartner = true;
    }

    return cond;
};

$(document).on('change', '.not-default-value-label [data-default-value]', function () {
    const element = $(this);
    let val = element.val();
    const partnerValue = element.data('partner-default');

    if (element.hasClass('form-check-input')) {
        val = element.prop('checked') ? 1 : 0;
    }

    const defaultVal = element.data().defaultValue;
    const defaultGroup = element.parents('.not-default-value-label');
    const showCond = window.checkDefaultCond(val, defaultVal, partnerValue, element);
    if (showCond.showDefault || showCond.showPartner) {
        defaultGroup.removeClass('is-default');
    } else {
        defaultGroup.addClass('is-default');
    }
});

let resetToDefaultDialogOpen = false;
$(document).on('click', '.not-default-value-label .input-group-reset', function () {
    if (resetToDefaultDialogOpen) {
        return;
    }
    const defaultGroup = $(this).parents('.not-default-value-label');
    const element = defaultGroup.find('[data-default-value]');
    let elementValue = element.val();
    let partnerValue = null;

    const { defaultValue } = element.data();
    let nodeName = element[0].nodeName.toLowerCase();

    if (element.hasClass('form-check-input')) {
        nodeName = 'switch';
        elementValue = element.prop('checked') ? 1 : 0;
    }

    const partnerElement = defaultGroup.find('[data-partner-default]');
    let isPartner = partnerElement.length > 0;
    if (isPartner) {
        partnerValue = partnerElement.data('partner-default');
        isPartner = partnerValue != elementValue;
    }

    let defaultValueText = defaultValue;

    let partnerDefaultValueText = null;

    if (isPartner) {
        partnerDefaultValueText = partnerValue;
    }

    let multiple = false;
    let multipleDefaultValue;
    let multiplePartnerDefaultValue;
    let options;

    switch (nodeName) {
        case 'input':
            break;
        case 'select':
            multiple = element[0].multiple;
            if (multiple) {
                multipleDefaultValue = defaultValue.split(',');
                defaultValueText = [];
                if (isPartner) {
                    multiplePartnerDefaultValue = partnerValue.split(',');
                    partnerDefaultValueText = [];
                }
            }
            options = $(element).find('option');
            $(options).each((_, item) => {
                if (multiple) {
                    const idx = multipleDefaultValue.indexOf(item.value);
                    if (idx >= 0) {
                        defaultValueText.push(item.text);
                    }
                    if (isPartner) {
                        const pidx = multiplePartnerDefaultValue.indexOf(item.value);
                        if (pidx >= 0) {
                            partnerDefaultValueText.push(item.text);
                        }
                    }
                } else {
                    if (item.value == defaultValue) {
                        defaultValueText = item.text;
                    }
                    if (isPartner && (item.value == partnerValue)) {
                        partnerDefaultValueText = item.text;
                    }
                }
            });
            if (multiple) {
                elementValue = elementValue.join(',');
                defaultValueText = defaultValueText.join(', ');
                if (isPartner) {
                    partnerDefaultValueText = partnerDefaultValueText.join(', ');
                }
            }
            break;
        case 'switch':
            defaultValueText = !defaultValue ? t('config', 'Off') : t('config', 'On');
            if (isPartner) {
                partnerDefaultValueText = !partnerValue ? t('config', 'Off') : t('config', 'On');
            }
            break;
        default:
            defaultValueText = '';
            break;
    }

    const resetText = t('config', 'Reset to system default');
    const resetPartnerText = t('config', 'Reset to partner default');
    const closeDialog = t('config', 'Cancel');

    const overleyId = 'splynx_dialog_reset_to_default_overley';
    const overley = $('<div>').addClass('ui-widget-overlay ui-front').attr('id', overleyId).css('z-index', 90);

    const showCond = window.checkDefaultCond(elementValue, defaultValue, partnerValue, element);

    let modalText;
    if (showCond.showDefault) {
        modalText = t('config', 'Are you sure you want to reset to system default value "{defaultValueText}"?', { defaultValueText });
        if (showCond.showPartner) {
            modalText += `</br>${t('config', 'or partner default value "{partnerDefaultValueText}"', { partnerDefaultValueText })}`;
        }
    }
    if (!showCond.showDefault && showCond.showPartner) {
        modalText = t('config', 'Are you sure you want to reset to partner default value "{partnerDefaultValueText}"?', { partnerDefaultValueText });
    }

    let buttons = {};
    if (showCond.showPartner) {
        buttons[resetPartnerText] = function () {
            switch (nodeName) {
                case 'input':
                case 'textarea':
                    element.val(partnerValue).trigger('change');
                    break;
                case 'select':
                    if (multiple) {
                        const options = $(element).find('option');
                        multipleDefaultValue = partnerValue.split(',');
                        $(options).each((_, item) => {
                            item.selected = multipleDefaultValue.indexOf(item.value) >= 0;
                        });
                        element.multipleSelect('refresh');
                        break;
                    }
                    element.val(partnerValue).trigger('change');
                    break;
                case 'switch':
                    element.trigger('click');
                    break;
                default:
                    break;
            }

            $(this).dialog('close');
        };
    }

    if (showCond.showDefault) {
        buttons[resetText] = function () {
            switch (nodeName) {
                case 'input':
                case 'textarea':
                    element.val(defaultValue).trigger('change');
                    break;
                case 'select':
                    if (multiple) {
                        const options = $(element).find('option');
                        multipleDefaultValue = defaultValue.split(',');
                        $(options).each((_, item) => {
                            item.selected = multipleDefaultValue.indexOf(item.value) >= 0;
                        });
                        element.multipleSelect('refresh');
                        break;
                    }
                    element.val(defaultValue).trigger('change');
                    break;
                case 'switch':
                    element.trigger('click');
                    break;
                default:
                    break;
            }
            $(this).dialog('close');
        };
    }

    buttons[closeDialog] = function () {
        $(this).dialog('close');
    };

    show_dialog(t('config', 'Warning!'), modalText, {
        class: 'spl-dialog admin-support-tickets-messages-split-dialog',
        dialog: {
            resizable: true,
            height: 250,
            width: 550,
            buttons,
        },
    }, () => {
        $('body').addClass('modal-open');
        $('body').append(overley);
        resetToDefaultDialogOpen = true;
    }, () => {
        resetToDefaultDialogOpen = false;
        $(`#${overleyId}`).remove();
        $('body').removeClass('modal-open');
    });
});

window.deleteRow = function (datatableVar) {
    let markForDeleteRows = datatableVar.rows('.mark-for-delete');

    if (markForDeleteRows[0].length === 0) {
        // mark if checkbox checked
        $(datatableVar.table().node()).find('.dt-checkboxes:checked').closest('tr').addClass('mark-for-delete');
        markForDeleteRows = datatableVar.rows('.mark-for-delete');
    }

    datatableVar.column(0).checkboxes.deselectAll();

    let rows = markForDeleteRows.nodes();
    if (datatableVar.settings()[0].oFeatures.bServerSide !== true) {
        markForDeleteRows.remove().columns.adjust();
        datatableVar.draw(false);
        return;
    }
    rows.each((el) => {
        $(el).removeClass('mark-for-delete').addClass('mark-deleted');
        $(el).find('input').attr('disabled', true);
        $(el).find('a').addClass('disabled').removeAttr('title');
        $(el).find('[onclick]').removeAttr('onclick');
        $(el).find('[data-url]').removeAttr('data-url');
        $(el).find('.active-tooltip').removeAttr('data-active-tooltip').removeClass('active-tooltip');
        $(el).off('click', 'a').on('click', 'a', (e) => {
            e.preventDefault();
            return false;
        });
        $(el).find('.show_short_ticket').webuiPopover('destroy');
    });
};

window.markForDelete = function (e) {
    let parent = $(e).closest('tr');
    if (parent.hasClass('child')) {
        parent.prev('tr.parent').addClass('mark-for-delete');
        return;
    }
    parent.addClass('mark-for-delete');
};

window.unmarkDelete = function () {
    $('.mark-for-delete').removeClass('mark-for-delete');
};

splynx_event_bus?.on('jq-tab-change', () => {
    $('.dataTable').each(function () {
        $(this).DataTable().draw();
    });
});

$(window).on('shown.webui.popover', (e) => {
    let closePopover = () => {
        let field = $(e.target);
        if (window.matchMedia('(min-width: 768px)').matches) {
            if (field.hasClass('active-tooltip') || field.hasClass('modal-item-popover')) {
                field.webuiPopover('destroy');
            } else {
                field.webuiPopover('hide');
            }
            if (field.hasClass('active-tooltip-show')) {
                field.click();
            }
        }
    };
    if ($('.ui-dialog').length || $('.vue-dialog-body').length) {
        $(window).on('scroll', closePopover);
    }
    $('.splynx-dialog-content').on('scroll', closePopover);
    $(window).on('resize', closePopover);
    $('.vue-dialog-body').on('scroll', closePopover);
});

splynx_event_bus?.on('vue-modal-loaded', (dialogWindow) => {
    let handler = () => {
        if (window.matchMedia('(max-width: 768px)').matches) {
            return false;
        }
        $('.vue-popover').parent().click();
        $(dialogWindow).off('scroll');
        setTimeout(() => {
            $(dialogWindow).on('scroll', handler);
        }, 1000);
    };
    $(dialogWindow).on('scroll', handler);
});

splynx_event_bus?.on('after_switch_page', () => {
    $('.splynx-breadcrumbs > ul').animate({ scrollLeft: $('.splynx-breadcrumbs > ul').width() * 2 }, 50);
});

window.switch_page_without_cache = function (url) {
    if (url[0] !== '/') {
        url = `/${url}`;
    }
    window.location.href = url;
};

splynx_event_bus?.on('page-activated', () => {
    $('.table').each(function () {
        if ($.fn.dataTable.isDataTable($(this))) {
            $(this).DataTable().responsive.recalc();
        }
    });
});

window.isValidEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
};

window.resetBootstrapTagInputField = () => {
    $('.bootstrap-tagsinput .tt-input').on('focusout', () => {
        let value = $('.bootstrap-tagsinput input[type=text].tt-input').val();
        if (window.isValidEmail(value)) {
            $('.bootstrap-tagsinput').parent().children('input').tagsinput('add', value);
        }
        setTimeout(() => {
            $('.bootstrap-tagsinput input[type=text].tt-input').val('');
        }, 0);
    });
};

window.open_dialog_for_preview_customer_document = (path, data) => {
    let dialog_preview_customer_document_id = new splynx_dialog(`/admin/${path}`, {
        dialog: {
            modal: true,
        },
    }, data).open();
    registerDialog(dialog_preview_customer_document_id);
};

window.reassign_messenger_chat = (chatId, type = 'customer') => {
    if (type !== 'lead') {
        type = 'customer';
    }
    window.showModal('messenger-app-re-assign-chat', {
        chatId,
        type,
    });
};

window.close_messenger_chat = (chatId, type) => new Promise((resolve) => {
    let url = '';
    if (type !== 'lead') {
        url = '/admin/customers/communication/messengers-chat--close-chat';
    } else {
        url = '/admin/crm/leads/communication/messengers-chat--close-chat';
    }
    window.vue_confirm_dialog(
        t('messengers', 'Are you sure you want to close this chat? This action will mark the chat as "Closed" and remove any current assignment.'),
        async () => {
            $.ajax({
                url,
                method: 'GET',
                data: {
                    chatId,
                },
                success: (response) => {
                    let { result, message } = response;
                    if (result) {
                        window.reloadLastDataTable();
                        show_success(message, 4);
                    } else {
                        show_error(message, 4);
                    }
                    resolve();
                },
            });
        },
        () => resolve(),
        () => resolve(),
    );
});

window.open_messenger_chat = (chatId, scrollToMessage = false, callback = () => {}) => {
    window.showModal('messenger-open-chat', {
        chatId,
        scrollToMessage,
        onDismiss: callback,
        onClose: callback,
    });
};

window.link_messenger_chat = (chatId) => {
    window.showModal('messenger-connect-chat', {
        chatId,
        onSubmit() {
            window.reloadLastDataTable();
        },
    });
};

window.create_from_unknown = (fullName, phoneNumber) => {
    window.showModal('messenger-create-from-unknown', {
        fullName,
        phoneNumber,
        onSubmit() {
            window.reloadLastDataTable();
        },
    });
};

window.start_messenger_conversation = (options, messengerId, sender, phone = null) => {
    if (!options.free && options.point_meta.type === 'modal') {
        const pointParams = {};
        if (sender) {
            pointParams.customer_id = sender;
        }
        window.showModal('add-on-link-modal', {
            props: {
                width: 500,
                height: 320,
                module: options.point_meta.module,
                point: options.point_meta.point,
                pointParams,
                params: {
                    messengerId,
                    phone,
                },
            },
        });
    }
    /* todo: Make modals for non add-ons messengers */
};

window.restore_messenger_chat = (author, startConversation, phone, messengerId) => {
    window.start_messenger_conversation(startConversation, messengerId, author, phone);
};

window.create_messenger_chat = ({ messenger, author }) => {
    const { startConversation, id } = messenger;
    window.start_messenger_conversation(startConversation, id, author);
};

window.addLabelsToTicket = (ids) => {
    window.showModal('mass-add-labels-to-ticket', {
        ids,
        onSubmit() {
            window.reloadLastDataTable();
        },
    });
};

$.extend($.fn.dataTable.defaults, {
    stateLoadParams(settings, data) {
        data.search.search = '';
    },
});
