<template>
    <div
        class="help-wrapper"
    >
        <x-translate
            tag="false"
            category="tariffs"
            message="Labels are used to define new service availability alongside tariff plans Labels can be configured on the {br} {link}"
        >
            <template #link>
                <a
                    class="info-link cursor-pointer"
                    @click="clickOnConfigPage"
                >
                    {{ $t('tariffs', 'Config page') }}
                </a>
            </template>
            <template #br>
                <br>
            </template>
        </x-translate>
    </div>
</template>

<script>
export default {
    name: 'CustomersLabelTooltip',
    methods: {
        clickOnConfigPage() {
            this.$router.push('/admin/config/labels');
        },
    },
};
</script>
