import { promisifiedAjax } from '@/utils/functions';

/**
 * Dependencies:
 * props:
 *  - newRecord
 *  - parentTaskId
 * data:
 *  - warningIncorrectValues
 *  - errorMessages
 *  - model
 *   - id
 *   - partner_id
 *   - project_id
 *   - related_customer_id
 *   - assigned_value
 */
export default {
    methods: {
        /** @method Call it after loadData */
        __partnersMixin_loadData() {
            if (!this.newRecord) this.checkPartners();
        },
        checkPartners() {
            if (this.model.partner_id) {
                let data = {
                    partner_id: this.model.partner_id,
                    project_id: this.model.project_id,
                    related_customer_id: this.model.related_customer_id,
                    assigned_value: this.model.assigned_value,
                    parent_task_id: this.parentTaskId,
                };

                promisifiedAjax({
                    type: 'POST',
                    url: `/admin/scheduling/tasks--check-partners?id=${this.model.id}&withOutModelName=true`,
                    data,
                }).then((response) => {
                    this.warningIncorrectValues = response;
                    this.errorMessages = [];
                });
            }
        },
    },
};
